<template>
  <div class="top-bar-nav__container flex space-between">
    <div class="top-bar-nav__branch flex align-center">
      <usense-logo-icon />

      <span>imini</span>
    </div>

    <div
      class="top-bar-nav__list-icon flex align-center justify-center"
      @click="visibleLeftNavBar = true"
    >
      <open-list-icon />
    </div>

    <a-drawer
      placement="left"
      :closable="false"
      :visible="visibleLeftNavBar"
      @close="visibleLeftNavBar = false"
    >
      <div class="side-bar-drawer__container flex align-center vertical">
        <div class="side-bar-drawer__mode flex align-center justify-center">
          <a-tooltip
            placement="left"
            trigger="click"
            color="#1E2433"
            v-model:visible="tooltipModeVisible"
            overlayClassName="tooltip-logout-drawer"
          >
            <template #title>
              <menu-jimini-icon
                v-if="props.mode !== 'laboratory'"
                class="mode-option__icon icon-2XL"
                @click="selectMenu('history')"
              />
              <menu-admin-icon
                v-else
                class="mode-option__icon icon-2XL"
                @click="selectMenu('user-management')"
              />

              <div v-if="props.mode !== 'laboratory'" class="text-center">
                <div class="text-small">
                  {{ $t("navigation-bar.laboratory-mode.title-1") }}
                </div>
                <div class="text-small">
                  {{ $t("navigation-bar.laboratory-mode.title-2") }}
                </div>
              </div>

              <div class="text-small" v-else>
                {{ $t("navigation-bar.admin-mode.title") }}
              </div>
            </template>

            <div class="side-bar-drawer__mode-action flex align-center gap-M">
              <menu-jimini-icon
                class="icon-2XL"
                v-if="props.mode === 'laboratory'"
              />
              <menu-admin-icon class="icon-2XL" v-else />

              <three-dot-green-icon
                class="side-bar-drawer__three-dot icon-XS"
                v-if="tooltipModeVisible"
              />
              <three-dot-white-icon
                class="side-bar-drawer__three-dot icon-XS"
                v-else
              />
            </div>
          </a-tooltip>
        </div>

        <div class="side-bar-drawer__divider"></div>

        <div
          class="side-bar-drawer__category flex space-between vertical gap-M"
          v-if="props.mode === 'laboratory'"
        >
          <div
            @click="selectMenu('history')"
            :class="{ active: activeMenu === 'history' }"
          >
            <history-active-icon
              class="icon-L"
              v-if="activeMenu === 'history'"
            />
            <history-white-icon class="icon-L" v-else />

            <span class="side-bar-drawer__label">
              {{ $t("navigation-bar.laboratory-mode.history") }}
            </span>
          </div>

          <div
            @click="selectMenu('devices')"
            :class="{ active: activeMenu === 'devices' }"
          >
            <devices-active-icon
              class="icon-L"
              v-if="activeMenu === 'devices'"
            />
            <devices-white-icon class="icon-L" v-else />

            <span class="side-bar-drawer__label">
              {{ $t("navigation-bar.laboratory-mode.device-management") }}
            </span>
          </div>

          <div
            @click="selectMenu('support')"
            :class="{ active: activeMenu === 'support' }"
          >
            <callback-devices-active-icon
              class="icon-L"
              v-if="activeMenu === 'support'"
            />
            <callback-devices-white-icon class="icon-L" v-else />

            <span class="side-bar-drawer__label">
              {{ $t("navigation-bar.laboratory-mode.support") }}
            </span>
          </div>
        </div>

        <div
          class="side-bar-drawer__category flex space-between vertical gap-M"
          v-else
        >
          <div
            @click="selectMenu('user-management')"
            :class="{ active: activeMenu === 'user-management' }"
          >
            <utilisateurs-active-icon
              class="icon-L"
              v-if="activeMenu === 'user-management'"
            />
            <utilisateurs-white-icon class="icon-L" v-else />

            <span class="side-bar-drawer__label">
              {{ $t("navigation-bar.admin-mode.user-managerment") }}
            </span>
          </div>

          <div
            @click="selectMenu('new-user')"
            :class="{ active: activeMenu === 'new-user' }"
          >
            <creauser-active-icon
              class="icon-L"
              v-if="activeMenu === 'new-user'"
            />
            <creauser-white-icon class="icon-L" v-else />

            <span class="side-bar-drawer__label">
              {{ $t("navigation-bar.admin-mode.user-creation") }}
            </span>
          </div>

          <div
            @click="selectMenu('organizations')"
            :class="{ active: activeMenu === 'organizations' }"
          >
            <organization-active-icon
              class="icon-L"
              v-if="activeMenu === 'organizations'"
            />
            <organization-white-icon class="icon-L" v-else />

            <span class="side-bar-drawer__label">
              {{ $t("navigation-bar.admin-mode.organization-management") }}
            </span>
          </div>
        </div>

        <div class="side-bar-drawer__divider"></div>

        <div class="side-bar-drawer__logo flex vertical align-center gap-S">
          <a-tooltip
            placement="left"
            trigger="click"
            color="#1E2433"
            v-model:visible="tooltipLogoutVisible"
            overlayClassName="tooltip-logout-drawer"
          >
            <template #title>
              <div
                class="account-option__icon flex vertical align-center"
                @click="selectMenu('account')"
              >
                <user-icon class="icon-M" />

                <div class="text-small">
                  {{ $t("navigation-bar.general.profile-1") }}
                </div>
                <div class="text-small">
                  {{ $t("navigation-bar.general.profile-2") }}
                </div>
              </div>

              <div
                class="logout-option__icon flex vertical align-center"
                @click="logoutHandler"
              >
                <log-out-icon class="icon-M" />

                <div class="logout-option__text text-small text-center">
                  {{ $t("navigation-bar.general.client-disconnect") }}
                </div>
              </div>
            </template>

            <div class="side-bar__logo flex align-center">
              <usense-logo-icon class="icon-3XL" />

              <three-dot-green-icon
                class="side-bar__three-dot icon-XS"
                v-if="tooltipLogoutVisible"
              />
              <three-dot-white-icon
                class="side-bar__three-dot icon-XS"
                v-else
              />
            </div>
          </a-tooltip>

          <span class="side-bar-drawer__version title-light text-small"
            >v. bêta</span
          >
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script lang="ts" setup>
import UsenseLogoIcon from "@/components/icons/usense-logo.vue";
import OpenListIcon from "@/components/icons/open-list.vue";
import HistoryActiveIcon from "@/components/icons/history-grey-no-border.vue";
import HistoryWhiteIcon from "@/components/icons/history-white-no-border.vue";
import DevicesWhiteIcon from "@/components/icons/clicscan-white.vue";
import DevicesActiveIcon from "@/components/icons/clicscan-green-no-border.vue";
import CallbackDevicesActiveIcon from "@/components/icons/clicscan-error-yellow.vue";
import CallbackDevicesWhiteIcon from "@/components/icons/clicscan-error-white-yellow.vue";
import ThreeDotGreenIcon from "@/components/icons/three-dot-green-vertical.vue";
import ThreeDotWhiteIcon from "@/components/icons/three-dot-white-vertical.vue";
import LogOutIcon from "@/components/icons/log-out.vue";
import MenuAdminIcon from "@/components/icons/menu-admin.vue";
import MenuJiminiIcon from "@/components/icons/menu-jimini.vue";
import UtilisateursWhiteIcon from "@/components/icons/utilisateurs-white.vue";
import UtilisateursActiveIcon from "@/components/icons/utilisateurs-green.vue";
import CreauserWhiteIcon from "@/components/icons/creauser-white.vue";
import CreauserActiveIcon from "@/components/icons/creauser-green.vue";
import OrganizationWhiteIcon from "@/components/icons/organization-white.vue";
import OrganizationActiveIcon from "@/components/icons/organization-green.vue";
import UserIcon from "@/components/icons/user-white-fill.vue";

import { inject, ref, watch } from "vue";
import { logout } from "@/services/auth";
import { useRoute } from "vue-router";

// eslint-disable-next-line no-undef
const props = defineProps({
  mode: {
    type: String,
    default: "admin",
  },
});

const visibleLeftNavBar = ref(false);
const tooltipLogoutVisible = ref<boolean>(false);
const tooltipModeVisible = ref<boolean>(false);
const route = useRoute();

const { activeMenu, selectMenu } = inject("active-menu");

const logoutHandler: () => void = () => {
  logout();
};

watch(activeMenu, () => {
  visibleLeftNavBar.value = false;
});

watch(
  () => route.path,
  () => {
    tooltipLogoutVisible.value = false;
    tooltipModeVisible.value = false;
  }
);
</script>

<style lang="scss" scoped>
.top-bar-nav {
  &__container {
    width: 100%;
    background-color: $colorBlueDarker;
    padding: $spaceS $spaceL;

    svg {
      width: $iconM;
      height: $icon2XL;
    }
  }

  &__branch {
    color: $colorGhostWhite;

    span {
      padding-top: $spaceS;
    }
  }

  &__list-icon {
    cursor: pointer;
    width: 6vmin;
    height: 6vmin;

    &:hover {
      background-color: $colorBlueNormal;
      border-radius: 50%;
    }
  }
}

.side-bar-drawer {
  &__container {
    width: 100%;
    height: 100%;
    padding: $spaceXL 0 $spaceL;
  }

  &__version {
    color: $colorBluePeriwinkle;
  }

  &__category {
    height: 54%;
    width: 100%;

    div {
      width: 100%;
      padding: $spaceL;
      display: flex;
      align-items: center;
      gap: $spaceM;
      cursor: pointer;

      .side-bar-drawer__label {
        color: $colorGhostWhite;
        font-weight: $weightL;
        font-size: $textS;
      }

      &.active {
        background-color: $colorGhostWhite;

        .side-bar-drawer__label {
          color: $colorPrussianBlue;
        }
      }
    }
  }

  &__divider {
    height: 12%;
    width: 100%;
  }

  &__mode {
    height: 12%;
    width: 100%;
    padding-left: 5.5vh;
  }

  &__logo {
    cursor: pointer;
    height: 12%;
    width: 100%;
  }
}

.logout-option__icon,
.side-bar-drawer__mode-action,
.account-option__icon,
.mode-option__icon {
  cursor: pointer;
}
.logout-option__text {
  width: 12.1vh;
}
</style>
