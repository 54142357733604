<template>
  <svg width="35" height="35" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_23_288" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="-1" y="0" width="41" height="39">
      <path d="M39.9999 0H-0.00012207V39H39.9999V0Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_23_288)">
      <path d="M27.6359 4.28576V1.69976C27.6365 1.47487 27.592 1.25215 27.5049 1.04481C27.4178 0.83747 27.2899 0.64974 27.1289 0.49276C26.7977 0.17172 26.3546 -0.0078125 25.8934 -0.0078125C25.4322 -0.0078125 24.989 0.17172 24.6579 0.49276C24.4967 0.649601 24.3687 0.837305 24.2816 1.04468C24.1945 1.25205 24.15 1.47484 24.1509 1.69976V4.28576C24.1524 4.5862 24.2339 4.88083 24.387 5.13935C24.54 5.39788 24.7592 5.61098 25.0219 5.75676C25.2881 5.90611 25.5882 5.98455 25.8934 5.98455C26.1986 5.98455 26.4987 5.90611 26.7649 5.75676C27.0276 5.61098 27.2468 5.39788 27.3998 5.13935C27.5529 4.88083 27.6344 4.5862 27.6359 4.28576Z" fill="#1E2433"/>
      <path d="M10.3728 4.28662V1.70062C10.3715 1.40001 10.2901 1.10518 10.137 0.846464C9.98395 0.587747 9.7647 0.374487 9.50184 0.228624C9.23563 0.0793632 8.93554 0.000976563 8.63034 0.000976562C8.32514 0.000976563 8.02505 0.0793632 7.75884 0.228624C7.49595 0.374275 7.27659 0.587322 7.12333 0.845856C6.97008 1.10439 6.88845 1.39908 6.88684 1.69962V4.28662C6.88804 4.58689 6.96918 4.88142 7.1219 5.13994C7.27463 5.39847 7.49344 5.61167 7.75584 5.75762C8.02205 5.90689 8.32214 5.98527 8.62734 5.98527C8.93254 5.98527 9.23263 5.90689 9.49884 5.75762C9.76211 5.61223 9.98187 5.3993 10.1355 5.14075C10.2891 4.88219 10.371 4.58737 10.3728 4.28662Z" fill="#1E2433"/>
      <path d="M29.2359 18.1709C26.5802 18.1693 24.0177 19.1495 22.0409 20.9229V18.6959H20.1249V24.4469H26.0249V22.5789H23.0399C23.9863 21.6472 25.131 20.9413 26.3885 20.5141C27.646 20.0869 28.9838 19.9494 30.3019 20.1119C31.6153 20.2663 32.8762 20.718 33.9888 21.4328C35.1015 22.1475 36.0365 23.1065 36.7229 24.2369C37.3955 25.3529 37.795 26.612 37.889 27.9116C37.983 29.2112 37.7688 30.5147 37.2639 31.7159C36.7472 32.9294 35.9587 34.008 34.9591 34.8685C33.9596 35.729 32.7757 36.3484 31.4989 36.6789C30.2132 37.0196 28.8672 37.0663 27.561 36.8154C26.2548 36.5646 25.0219 36.0226 23.9539 35.2299C22.9025 34.4518 22.0475 33.4386 21.4572 32.2713C20.8668 31.1041 20.5575 29.815 20.5539 28.5069H18.6349C18.6373 29.8703 18.9128 31.2195 19.4452 32.4747C19.9777 33.7298 20.7562 34.8656 21.7349 35.8149C23.747 37.7567 26.4341 38.8418 29.2304 38.8418C32.0266 38.8418 34.7138 37.7567 36.7259 35.8149C37.7077 34.8678 38.4886 33.7325 39.022 32.4769C39.5554 31.2213 39.8303 29.8711 39.8303 28.5069C39.8303 27.1427 39.5554 25.7925 39.022 24.5369C38.4886 23.2813 37.7077 22.146 36.7259 21.1989C34.7183 19.2535 32.0314 18.1673 29.2359 18.1709Z" fill="#00F9B2"/>
      <path d="M28.2759 22.8257V29.4387H34.1759V27.5707H30.1929V22.8257H28.2759Z" fill="#1E2433"/>
      <path d="M34.5239 17.7469V6.85887C34.5229 6.38618 34.4272 5.91849 34.2423 5.48342C34.0575 5.04836 33.7874 4.65476 33.4479 4.32587C32.751 3.65077 31.8181 3.27438 30.8479 3.27687H29.2729V4.28587C29.2701 4.86883 29.1121 5.44053 28.8151 5.94215C28.518 6.44377 28.0927 6.85719 27.5829 7.13987C27.0665 7.42955 26.4844 7.58169 25.8924 7.58169C25.3003 7.58169 24.7182 7.42955 24.2019 7.13987C23.6921 6.85711 23.2668 6.44367 22.9698 5.94207C22.6728 5.44047 22.5147 4.86881 22.5119 4.28587V3.27687H12.0119V4.28587C12.0091 4.86883 11.8511 5.44053 11.5541 5.94215C11.257 6.44377 10.8317 6.85719 10.3219 7.13987C9.80569 7.42946 9.22375 7.58156 8.63188 7.58156C8.04001 7.58156 7.45806 7.42946 6.94188 7.13987C6.43203 6.85719 6.00671 6.44377 5.70968 5.94215C5.41264 5.44053 5.25462 4.86883 5.25188 4.28587V3.27687H3.67288C2.70265 3.27449 1.7698 3.65086 1.07288 4.32587C0.733958 4.65504 0.464402 5.04877 0.280114 5.48381C0.0958262 5.91885 0.000540163 6.3864 -0.00012207 6.85887L-0.00012207 30.0789C0.000929968 30.5515 0.0966875 31.0192 0.281495 31.4543C0.466303 31.8893 0.736415 32.2829 1.07588 32.6119C1.77286 33.2868 2.70567 33.6631 3.67588 33.6609H18.1999C17.9038 33.0597 17.6602 32.434 17.4719 31.7909H3.67388C3.21089 31.7921 2.76567 31.6128 2.43288 31.2909C2.27049 31.1339 2.14124 30.9459 2.05278 30.738C1.96432 30.5302 1.91845 30.3067 1.91788 30.0809V11.0629H32.6069V17.0379C33.2647 17.2208 33.9062 17.458 34.5249 17.7469H34.5239Z" fill="#1E2433"/>
    </g>
  </svg>
</template>
