import { getItemFromLocalStorage } from "@/services/utils";
import { initAuth, logout, parseToken, renewAccessToken, storeToken } from "@/services/auth";

const checkAuthEachRoute: () => void = async () => {
    const accessToken = getItemFromLocalStorage("access_token");

    if (!accessToken) {
        await initAuth();
    } else {
        const parseAccessToken = parseToken(accessToken);
        if (!parseAccessToken) {
            localStorage.clear();
            logout();
        } else {
            if (parseAccessToken.exp < Date.now() / 1000) {
                await initAuth();
            }
        }
    }
};

const isLogout: () => boolean = () => {
    const accessToken = getItemFromLocalStorage("access_token");

    if (!accessToken) {
        return true;
    }

    return false;
};

export {
    checkAuthEachRoute,
    isLogout,
};
