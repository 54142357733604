<template>
  <div class="landing__container">
    <img :src="require('@/assets/imgs/Jimini-welcome.png')" />
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";

import { getItemFromLocalStorage } from "@/services/utils";
import { initAuth } from "@/services/auth";

onMounted(async () => {
  const accessToken = getItemFromLocalStorage("access_token");
  if (accessToken) {
    await useRouter().push({ path: "/laboratory/history" });
  } else {
    await initAuth();
  }
});
</script>

<style lang="scss" scoped>
.landing__container {
  img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
}
</style>
