<template>
  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3.55029" y="2.36694" width="62.7219" height="65.0888" rx="20" fill="#009CA5"/>
    <path d="M49.7139 34.7683H29.5289V36.6637H49.7139V34.7683Z" fill="#00F9B2"/>
    <path d="M49.7139 38.2761H29.5289V40.1703H49.7139V38.2761Z" fill="#F7F8FB"/>
    <path d="M49.7139 42.1606H29.5289V44.0567H49.7139V42.1606Z" fill="#F7F8FB"/>
    <path d="M49.7144 17.6631H47.8208V29.4151H49.7144V17.6631Z" fill="#00F9B2"/>
    <path d="M45.45 22.2107H43.557V29.414H45.45V22.2107Z" fill="#F7F8FB"/>
    <mask id="mask0_716_11264" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="8" y="11" width="48" height="49">
      <path d="M55.6213 11.8342H8.28406V59.8827H55.6213V11.8342Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_716_11264)">
      <path d="M30.556 17.7666V23.1108H35.8991C35.7687 21.7378 35.1639 20.4531 34.1887 19.4778C33.2136 18.5024 31.929 17.8973 30.556 17.7666Z" fill="#F7F8FB"/>
      <path d="M35.8991 24.2485H30.2705L26.2311 28.2867C27.0615 28.9765 28.065 29.4257 29.1326 29.5857C30.2003 29.7457 31.2913 29.6103 32.2875 29.1942C33.2836 28.7781 34.1469 28.0973 34.7835 27.2254C35.4202 26.3536 35.806 25.3241 35.8991 24.2485Z" fill="#00F9B2"/>
      <path d="M29.4179 17.7666C28.3368 17.8608 27.3023 18.2505 26.4279 18.8933C25.5535 19.5361 24.8727 20.407 24.4602 21.4108C24.0477 22.4146 23.9193 23.5125 24.0891 24.5844C24.2588 25.6563 24.7202 26.6608 25.4228 27.488L29.4179 23.4916V17.7666Z" fill="#F7F8FB"/>
      <path d="M51.26 11.8342H22.4542C21.2978 11.8354 20.1891 12.2952 19.3714 13.1129C18.5538 13.9306 18.0939 15.0392 18.0928 16.1956V36.0877C18.8596 36.092 19.6232 36.1883 20.3671 36.3745V16.1956C20.3678 15.6423 20.5879 15.1118 20.9791 14.7206C21.3704 14.3293 21.9008 14.1092 22.4542 14.1086H51.26C51.8133 14.1091 52.3439 14.3291 52.7352 14.7204C53.1265 15.1117 53.3465 15.6422 53.347 16.1956L53.3567 47.2855C53.2398 47.6239 53.0708 47.942 52.8559 48.2283H47.474C46.6191 48.2285 45.7992 48.5681 45.1946 49.1725C44.5899 49.7769 44.25 50.5967 44.2495 51.4516V57.0778C43.9713 57.2982 43.6625 57.477 43.3327 57.6084H22.4542C21.9008 57.6077 21.3704 57.3876 20.9791 56.9964C20.5879 56.6051 20.3678 56.0747 20.3671 55.5214V54.9793C19.6233 55.166 18.8597 55.2625 18.0928 55.2667V55.5214C18.0939 56.6777 18.5538 57.7864 19.3714 58.6041C20.1891 59.4217 21.2978 59.8816 22.4542 59.8827H42.9222C43.9732 59.852 44.9759 59.4349 45.7387 58.7113C46.7137 57.8927 53.675 50.7768 54.3862 49.8976C54.8048 49.4635 55.1303 48.9485 55.3427 48.3841C55.5551 47.8197 55.6499 47.2178 55.6213 46.6155V16.1956C55.6202 15.0392 55.1604 13.9306 54.3427 13.1129C53.525 12.2952 52.4163 11.8354 51.26 11.8342Z" fill="#C6D6E2"/>
      <path d="M18.764 55.7513C16.6913 55.7512 14.6652 55.1365 12.9418 53.9849C11.2185 52.8332 9.87534 51.1965 9.08223 49.2815C8.28913 47.3665 8.08168 45.2594 8.48612 43.2265C8.89055 41.1937 9.88872 39.3264 11.3544 37.8608C12.82 36.3952 14.6874 35.3972 16.7203 34.9928C18.7532 34.5885 20.8603 34.7961 22.7752 35.5893C24.6901 36.3825 26.3268 37.7258 27.4784 39.4492C28.6299 41.1726 29.2445 43.1987 29.2445 45.2714C29.2419 48.0502 28.1369 50.7144 26.172 52.6792C24.207 54.644 21.5427 55.7489 18.764 55.7513ZM18.764 36.9264C17.1135 36.9264 15.5001 37.4158 14.1277 38.3328C12.7554 39.2498 11.6858 40.5531 11.0542 42.0779C10.4226 43.6028 10.2573 45.2807 10.5793 46.8995C10.9013 48.5182 11.6961 50.0052 12.8632 51.1722C14.0302 52.3393 15.5172 53.1341 17.136 53.4561C18.7547 53.7781 20.4326 53.6128 21.9575 52.9812C23.4823 52.3496 24.7856 51.28 25.7026 49.9077C26.6196 48.5353 27.109 46.9219 27.109 45.2714C27.1066 43.0589 26.2266 40.9377 24.6621 39.3733C23.0977 37.8088 20.9765 36.9288 18.764 36.9264Z" fill="#F7F8FB"/>
      <path d="M18.7639 45.376C19.4199 45.376 20.0611 45.1815 20.6066 44.817C21.152 44.4526 21.5771 43.9346 21.8281 43.3286C22.0791 42.7225 22.1448 42.0557 22.0169 41.4123C21.8889 40.7689 21.573 40.1779 21.1092 39.7141C20.6453 39.2503 20.0543 38.9344 19.411 38.8064C18.7676 38.6784 18.1007 38.7441 17.4947 38.9951C16.8887 39.2462 16.3707 39.6713 16.0062 40.2167C15.6418 40.7621 15.4473 41.4034 15.4473 42.0593C15.4472 42.4949 15.5329 42.9262 15.6996 43.3287C15.8662 43.7311 16.1105 44.0968 16.4185 44.4047C16.7265 44.7127 17.0922 44.957 17.4946 45.1237C17.897 45.2903 18.3284 45.3761 18.7639 45.376ZM18.7639 39.691C19.2325 39.691 19.6905 39.8299 20.08 40.0902C20.4696 40.3505 20.7733 40.7205 20.9526 41.1534C21.1319 41.5863 21.1788 42.0626 21.0874 42.5221C20.996 42.9816 20.7703 43.4037 20.439 43.735C20.1077 44.0664 19.6856 44.292 19.2261 44.3834C18.7666 44.4748 18.2902 44.4279 17.8574 44.2486C17.4245 44.0693 17.0545 43.7656 16.7942 43.3761C16.5339 42.9865 16.395 42.5285 16.395 42.0599C16.395 41.4317 16.6446 40.8291 17.0888 40.3848C17.5331 39.9406 18.1356 39.691 18.7639 39.691Z" fill="#F7F8FB"/>
      <path d="M19.7116 45.8787H17.8162C16.6951 45.8863 15.6221 46.335 14.8294 47.1277C14.0367 47.9205 13.5879 48.9935 13.5803 50.1145C13.5805 50.2326 13.6275 50.3458 13.7111 50.4293C13.7946 50.5127 13.9079 50.5596 14.026 50.5596H23.5018C23.6198 50.5596 23.733 50.5127 23.8165 50.4292C23.9 50.3458 23.9469 50.2326 23.9469 50.1145C23.9394 48.9934 23.4907 47.9203 22.6979 47.1276C21.9052 46.3348 20.8321 45.8861 19.711 45.8787M14.4965 49.6689C14.6054 48.8662 15.0014 48.1302 15.6111 47.597C16.2209 47.0639 17.0032 46.7696 17.8132 46.7688H19.7086C20.5188 46.7693 21.3013 47.0634 21.9113 47.5966C22.5213 48.1298 22.9175 48.866 23.0264 49.6689H14.4965Z" fill="#F7F8FB"/>
    </g>
    <path d="M43.0605 70H26.9395C19.7971 69.9921 12.9495 67.1513 7.89909 62.1009C2.84866 57.0505 0.00787449 50.2029 0 43.0605L0 26.9395C0.00787449 19.7971 2.84866 12.9495 7.89909 7.89909C12.9495 2.84866 19.7971 0.00787449 26.9395 0L43.0605 0C50.2029 0.00787449 57.0505 2.84866 62.1009 7.89909C67.1513 12.9495 69.9921 19.7971 70 26.9395V43.0605C69.9921 50.2029 67.1513 57.0505 62.1009 62.1009C57.0505 67.1513 50.2029 69.9921 43.0605 70ZM26.9395 4.144C20.8961 4.15095 15.1021 6.5549 10.8289 10.8284C6.55574 15.102 4.15223 20.8961 4.14575 26.9395V43.0605C4.1527 49.1036 6.55641 54.8973 10.8296 59.1704C15.1027 63.4436 20.8964 65.8473 26.9395 65.8542H43.0605C49.1036 65.8473 54.8973 63.4436 59.1704 59.1704C63.4436 54.8973 65.8473 49.1036 65.8542 43.0605V26.9395C65.8478 20.8961 63.4443 15.102 59.1711 10.8284C54.8979 6.5549 49.104 4.15095 43.0605 4.144H26.9395Z" fill="#009CA5"/>
  </svg>
</template>
