<template>
  <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1118_4127" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="58" height="58">
      <path d="M57.258 0H0V57.258H57.258V0Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_1118_4127)">
      <path d="M35.222 57.258H22.035C16.1928 57.2514 10.5918 54.9276 6.46086 50.7964C2.3299 46.6653 0.00635222 41.0642 0 35.222L0 22.035C0.00661685 16.193 2.33028 10.5921 6.46121 6.46121C10.5921 2.33028 16.193 0.00661685 22.035 0L35.222 0C41.0642 0.00635222 46.6653 2.3299 50.7964 6.46086C54.9276 10.5918 57.2514 16.1928 57.258 22.035V35.222C57.2516 41.0644 54.928 46.6656 50.7968 50.7968C46.6656 54.928 41.0644 57.2516 35.222 57.258ZM22.035 3.39C17.0917 3.39556 12.3525 5.36172 8.85713 8.85713C5.36172 12.3525 3.39556 17.0917 3.39 22.035V35.222C3.39582 40.1652 5.36207 44.9042 8.85742 48.3996C12.3528 51.8949 17.0918 53.8612 22.035 53.867H35.222C40.1653 53.8614 44.9047 51.8953 48.4002 48.3999C51.8958 44.9045 53.8622 40.1653 53.868 35.222V22.035C53.8624 17.0916 51.8961 12.3522 48.4005 8.85678C44.9049 5.36134 40.1654 3.39529 35.222 3.39H22.035Z" fill="#FED658"/>
    </g>
    <path d="M47.2577 28.7009C47.162 28.6043 47.0482 28.5277 46.9227 28.4754C46.7972 28.4231 46.6626 28.3961 46.5267 28.3961C46.3908 28.3961 46.2562 28.4231 46.1307 28.4754C46.0052 28.5277 45.8914 28.6043 45.7957 28.7009L44.8057 29.6909C44.9684 27.3588 44.6066 25.0198 43.7469 22.8458C42.8872 20.6718 41.5514 18.718 39.8377 17.1279C36.88 14.393 32.9755 12.9143 28.9482 13.0038C24.9208 13.0934 21.0859 14.7442 18.2527 17.6079C17.247 18.6241 16.385 19.7731 15.6907 21.0229C15.6198 21.1505 15.5753 21.2911 15.5596 21.4362C15.544 21.5813 15.5576 21.7281 15.5997 21.8679C15.6425 22.0072 15.7132 22.1364 15.8074 22.2476C15.9015 22.3588 16.0173 22.4498 16.1477 22.5149C16.3004 22.5885 16.4682 22.6255 16.6377 22.6229C16.8304 22.6233 17.0197 22.5719 17.1858 22.474C17.3518 22.3762 17.4886 22.2356 17.5817 22.0669C18.5838 20.2495 19.9954 18.6906 21.7046 17.5136C23.4139 16.3365 25.3738 15.5739 27.4291 15.2859C29.4843 14.998 31.5784 15.1928 33.5453 15.8548C35.5122 16.5168 37.2978 17.6279 38.7607 19.0999C40.1825 20.5251 41.2656 22.2519 41.9298 24.1523C42.594 26.0528 42.8223 28.0783 42.5977 30.0789L41.1587 28.6699C40.9683 28.4831 40.7118 28.3792 40.4451 28.3807C40.1783 28.3822 39.923 28.489 39.7347 28.6779C39.6406 28.7718 39.566 28.8833 39.5151 29.0061C39.4642 29.1289 39.438 29.2605 39.438 29.3934C39.438 29.5263 39.4642 29.6579 39.5151 29.7807C39.566 29.9035 39.6406 30.015 39.7347 30.1089L42.9937 33.3749C43.1351 33.5162 43.3268 33.5956 43.5267 33.5956C43.7266 33.5956 43.9183 33.5162 44.0597 33.3749L47.2647 30.1619C47.4556 29.9662 47.5619 29.7033 47.5606 29.43C47.5592 29.1566 47.4505 28.8947 47.2577 28.7009Z" fill="#FFD658"/>
    <path d="M42.4297 34.7009C42.2784 34.6257 42.1117 34.5867 41.9427 34.5869C41.75 34.5866 41.5607 34.638 41.3947 34.7358C41.2286 34.8336 41.0919 34.9742 40.9987 35.1429C39.9951 36.9597 38.5826 38.5182 36.8729 39.695C35.1632 40.8718 33.2031 41.6348 31.1478 41.9235C29.0924 42.2123 26.998 42.019 25.0302 41.3589C23.0624 40.6987 21.2751 39.5898 19.8097 38.1199C18.3972 36.6932 17.3204 34.9696 16.6579 33.0743C15.9953 31.1791 15.7637 29.16 15.9797 27.1639L17.3797 28.5729C17.5621 28.7533 17.8082 28.8545 18.0647 28.8545C18.3212 28.8545 18.5674 28.7533 18.7497 28.5729L18.8497 28.4729C19.029 28.2914 19.1295 28.0466 19.1295 27.7914C19.1295 27.5363 19.029 27.2915 18.8497 27.1099L15.5837 23.8349C15.4423 23.6936 15.2506 23.6143 15.0507 23.6143C14.8508 23.6143 14.6591 23.6936 14.5177 23.8349L11.3177 27.0559C11.2185 27.1512 11.1393 27.2653 11.0848 27.3916C11.0302 27.5178 11.0014 27.6537 11.0001 27.7913C10.9987 27.9288 11.0247 28.0652 11.0767 28.1925C11.1287 28.3199 11.2056 28.4356 11.3029 28.5328C11.4001 28.6301 11.5158 28.707 11.6431 28.7589C11.7705 28.8109 11.9069 28.837 12.0444 28.8356C12.1819 28.8342 12.3178 28.8054 12.4441 28.7509C12.5703 28.6963 12.6845 28.6171 12.7797 28.5179L13.7697 27.5179C13.6336 29.4853 13.8659 31.4608 14.4547 33.3429C15.4462 36.5005 17.4201 39.259 20.0886 41.2165C22.7572 43.1741 25.9812 44.2284 29.2907 44.2259C31.2978 44.2262 33.2859 43.8384 35.1457 43.0839C37.0756 42.2995 38.826 41.1313 40.2907 39.6499C41.3143 38.6244 42.1894 37.4608 42.8907 36.1929C42.9628 36.0655 43.0081 35.9247 43.0237 35.7792C43.0393 35.6336 43.0249 35.4864 42.9814 35.3466C42.938 35.2068 42.8663 35.0774 42.7709 34.9663C42.6755 34.8553 42.5584 34.765 42.4267 34.7009H42.4297Z" fill="#FFD658"/>
  </svg>
</template>
