import { UserManager, User } from "oidc-client";
import {
  setItemToLocalStorage,
  removeItemFromLocalStorage,
} from "@/services/utils";

const authConfig = {
  authority: process.env.VUE_APP_AUTH_BASE_URL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
  redirect_uri: process.env.VUE_APP_BASE_REDIRECT_URI,
  response_type: "code",
  scope: "openid profile jiminiapi",
  post_logout_redirect_uri: process.env.VUE_APP_BASE_CALLBACK_URI,
};

const oidcUserManager = new UserManager(authConfig);

export const initAuth: () => Promise<void> = () => {
  return oidcUserManager.signinRedirect();
};

export const callbackAuth: () => Promise<User> = () => {
  return new UserManager({ response_mode: "query" }).signinRedirectCallback();
};

export const renewAccessToken: () => Promise<User> = () => {
  return oidcUserManager.signinSilent();
};

export const logout: () => void = () => {
  localStorage.clear();
  oidcUserManager.signoutRedirect().then(() => {
    console.log("Signout from Authorization Server");
  });
};

export const storeToken: (user: User) => void = (user) => {
  setItemToLocalStorage("access_token", user.access_token);
  setItemToLocalStorage(
    "refresh_token",
    user.refresh_token ? user.refresh_token : ""
  );
};

export const removeToken: () => void = () => {
  removeItemFromLocalStorage("access_token");
  removeItemFromLocalStorage("refresh_token");
};

export const parseToken: (token: string) => any = (token) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = decodeURIComponent(
        atob(base64Url)
            .split("")
            .map((character: string) => {
              return "%" + ("00" + character.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(base64);
  } catch (error) {
    return null;
  }
};
