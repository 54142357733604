<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 256 256"
      xml:space="preserve"
  >

<defs>
</defs>
    <g
        style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
        transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
    >
	<path
      d="M 85.814 21.474 h -58.21 c -2.312 0 -4.186 -1.874 -4.186 -4.186 s 1.874 -4.186 4.186 -4.186 h 58.21 c 2.312 0 4.186 1.874 4.186 4.186 S 88.126 21.474 85.814 21.474 z"
      style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #F7F8FB; fill-rule: nonzero; opacity: 1;"
      transform=" matrix(1 0 0 1 0 0) "
      stroke-linecap="round"
  />
      <path
          d="M 85.814 49.186 h -58.21 c -2.312 0 -4.186 -1.874 -4.186 -4.186 s 1.874 -4.186 4.186 -4.186 h 58.21 C 88.126 40.814 90 42.688 90 45 S 88.126 49.186 85.814 49.186 z"
          style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #F7F8FB; fill-rule: nonzero; opacity: 1;"
          transform=" matrix(1 0 0 1 0 0) "
          stroke-linecap="round"
      />
      <path
          d="M 85.814 76.897 h -58.21 c -2.312 0 -4.186 -1.874 -4.186 -4.186 s 1.874 -4.186 4.186 -4.186 h 58.21 c 2.312 0 4.186 1.874 4.186 4.186 S 88.126 76.897 85.814 76.897 z"
          style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #F7F8FB; fill-rule: nonzero; opacity: 1;"
          transform=" matrix(1 0 0 1 0 0) "
          stroke-linecap="round"
      />
      <circle
          cx="7.558"
          cy="17.288"
          r="7.558"
          style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #F7F8FB; fill-rule: nonzero; opacity: 1;"
          transform="  matrix(1 0 0 1 0 0) "
      />
      <circle
          cx="7.558"
          cy="44.998"
          r="7.558"
          style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #F7F8FB; fill-rule: nonzero; opacity: 1;"
          transform="  matrix(1 0 0 1 0 0) "
      />
      <circle
          cx="7.558"
          cy="72.708"
          r="7.558"
          style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #F7F8FB; fill-rule: nonzero; opacity: 1;"
          transform="  matrix(1 0 0 1 0 0) "
      />
</g>
</svg>
</template>