<template>
  <div class="logout flex vertical align-center justify-center gap-XL">
    <div class="logout-message title-bolder text-largest">Vous êtes bien déconnecté !</div>
    <a-button class="ant-btn-primary fit-content login" @click="login">
      <template #icon>
        Retour à l’accueil
        <next-icon />
      </template>
    </a-button>
  </div>
</template>

<script lang="ts" setup>
import NextIcon from "@/components/icons/next-white.vue";
import { useRouter } from "vue-router";

const router = useRouter();
const login: () => void = () => {
  router.push({ name: "welcome" });
};
</script>

<style lang="scss" scoped>
.logout {
  height: 100vh;
  width: 100vw;
  background-color: $colorBlueDark;

  &-message {
    color: $colorWhite;
  }

  .login {
    font-weight: $weightL;
    font-size: $textL;
  }
}
</style>
