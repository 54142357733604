import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { User } from "oidc-client";

import { callbackAuth, storeToken } from "@/services/auth";
import { checkAuthEachRoute, isLogout } from "@/router/helper";

import LayoutMain from "@/views/layouts/layout-main.vue";
import LayoutRedirect from "@/views/layouts/layout-redirect.vue";
import LayoutLanding from "@/views/layouts/layout-landing.vue";
import LayoutLogout from "@/views/layouts/layout-logout.vue";

// ----------------------------------- Laboratory Environment ------------------------------------------------------
const HistoryList = () => import("@/views/laboratory/history/history-list.vue");
const HistoryReport = () =>
  import("@/views/laboratory/history/history-report.vue");
const DeviceMonitoring = () =>
  import("@/views/laboratory/device/device-monitoring.vue");
const CallbackDevices = () => import("@/views/layouts/support.vue");
const Error = () => import("@/views/layouts/error.vue");
const UserProfile = () => import("@/views/laboratory/account/profile.vue");
// -----------------------------------------------------------------------------------------------------------------

// ----------------------------------- Admin Environment ------------------------------------------------------
const UserManagement = () => import("@/views/admin/user/management.vue");
const UserInformation = () => import("@/views/admin/user/person.vue");
const CreateUser = () => import("@/views/admin/user/new-user/new-user.vue");
const Organization = () =>
  import("@/views/admin/organization/organization.vue");
const OrganizationDetail = () =>
  import("@/views/admin/organization/organization-detail.vue");
const NewOrganization = () =>
  import("@/views/admin/organization/new-organization.vue");

const routes: Array<RouteRecordRaw> = [
  {
    path: "/welcome",
    name: "welcome",
    component: LayoutLanding,
  },
  {
    path: "/signout-callback-oidc",
    name: "log-out",
    component: LayoutLogout,
  },
  {
    path: "/",
    component: LayoutMain,
    children: [
      {
        path: "/laboratory",
        name: "laboratory",
        children: [
          {
            path: "history",
            name: "history",
            component: HistoryList,
          },
          {
            path: "report/:measureId",
            name: "detail-result",
            component: HistoryReport,
          },
          {
            path: "devices",
            name: "devices",
            component: DeviceMonitoring,
          },
        ],
      },
      {
        path: "/admin",
        name: "admin",
        children: [
          {
            path: "user-management",
            name: "user-management",
            component: UserManagement,
          },
          {
            path: "personal/:userId",
            name: "personal",
            component: UserInformation,
          },
          {
            path: "new-user",
            name: "new-user",
            component: CreateUser,
          },
          {
            path: "organizations",
            name: "organization",
            children: [
              {
                path: "",
                name: "organizations",
                component: Organization,
              },
              {
                path: ":organizationId",
                name: "organization-detail",
                component: OrganizationDetail,
              },
            ],
          },
          {
            path: "new-organization",
            name: "new-organization",
            component: NewOrganization,
          },
        ],
      },
      {
        path: "/account",
        name: "account",
        component: UserProfile,
      },
      {
        path: "support",
        name: "support",
        component: CallbackDevices,
      },
      {
        path: "error",
        name: "error",
        component: Error,
        props: (route) => ({
          code: route.query.code || null,
        }),
      },
    ],
  },
  {
    path: "/oauth2-redirect.html",
    component: LayoutRedirect,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.path === "/") {
    next({ path: "/welcome" });
  } else if (to.path === "/oauth2-redirect.html") {
    const loginData: User = await callbackAuth();
    storeToken(loginData);

    next({ path: "/laboratory/history" });
  } else if (to.path === "/signout-callback-oidc") {
    if (isLogout()) {
      next();
    } else {
      next({ path: "/account" });
    }
  } else if (to.path !== "/welcome" && to.path !== "/signout-callback-oidc") {
    await checkAuthEachRoute();

    if (to.path === "/laboratory") {
      next({ path: "/laboratory/history" });
    } else if (to.path === "/admin") {
      next({ path: "" });
    } else {
      next();
    }
  } else {
    next();
  }
});
export { routes };
export default router;
