<template>
  <svg
      width="41"
      height="45"
      viewBox="0 0 41 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M10.5 5.5C10.5 8.26142 8.26142 10.5 5.5 10.5C2.73858 10.5 0.5 8.26142 0.5 5.5C0.5 2.73858 2.73858 0.5 5.5 0.5C8.26142 0.5 10.5 2.73858 10.5 5.5Z"
        fill="#00F9B2"
    />
    <path
        d="M10.5 22.5C10.5 25.2614 8.26142 27.5 5.5 27.5C2.73858 27.5 0.5 25.2614 0.5 22.5C0.5 19.7386 2.73858 17.5 5.5 17.5C8.26142 17.5 10.5 19.7386 10.5 22.5Z"
        fill="#00F9B2"
    />
    <path
        d="M10.5 39.5C10.5 42.2614 8.26142 44.5 5.5 44.5C2.73858 44.5 0.5 42.2614 0.5 39.5C0.5 36.7386 2.73858 34.5 5.5 34.5C8.26142 34.5 10.5 36.7386 10.5 39.5Z"
        fill="#00F9B2"
    />
  </svg>
</template>
