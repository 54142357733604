<template>
  <div class="top-bar-search__container bg-white flex align-center space-between">
    <a-input v-model:value="search" :placeholder="$t('search-bar.general.placeholder')" disabled>
      <template #prefix>
        <search-icon class="icon-2XS" />
      </template>
    </a-input>

    <span class="top-bar__organization-title title-bold text-large">
      {{ titleText }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import SearchIcon from "@/components/icons/search-grey.vue";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n()
// eslint-disable-next-line no-undef
const props = defineProps({
  mode: {
    type: String,
    default: "admin",
  }
});

const search = ref(null);

const titleText = computed(() => {
  return props.mode !== "laboratory" ? t("search-bar.admin-mode.title") : t("search-bar.laboratory-mode.title");
});
</script>

<style lang="scss" scoped>
.top-bar-search__container {
  padding: $spaceL $spaceXL;
  box-shadow: $shadowXS;
  border-radius: $radiusXL;

  .ant-input-affix-wrapper {
    width: 70%;
  }

  :deep(.ant-input) {
    background-color: $colorGhostWhite;
    color: $colorBlueDark;
    font-weight: $weightM;
  }

  .top-bar__organization-title {
    color: $colorBlueDarker;
  }
}
</style>
