import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { createPinia } from "pinia";

import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import "@/assets/scss/main.scss";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import i18n from "@/middlewares/i18n";
const pinia = createPinia();

createApp(App)
  .use(i18n)
  .use(Antd)
  .use(PerfectScrollbar)
  .use(pinia)
  .use(router)
  .mount("#app");
