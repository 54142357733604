<template>
  <div class="flex align-center main-layout" :class="{ vertical: navOnTop }">
    <side-bar :mode="currentMode" v-if="!navOnTop" />

    <top-bar-nav :mode="currentMode" v-else />
    <perfect-scrollbar class="main-content__container">
      <perfect-scrollbar
        class="bg-ghost-white main-content__body flex vertical flex-start align-center gap-L"
        id="main-content__body"
      >
        <top-bar-search :mode="currentMode" />
        <router-view></router-view>
      </perfect-scrollbar>
    </perfect-scrollbar>
  </div>
</template>

<script lang="ts" setup>
import SideBar from "@/components/common/side-bar.vue";
import TopBarSearch from "@/components/common/top-bar-search.vue";
import TopBarNav from "@/components/common/top-bar-nav.vue";

import { ref, provide, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { authStoreManagement } from "@/store/auth";
import { getItemFromLocalStorage } from "@/services/utils";
import { parseToken } from "@/services/auth";

const viewPointCondition = () => {
  return window.innerWidth / window.innerHeight < 1.75;
};

const navOnTop = ref<boolean>(viewPointCondition());
const activeMenu = ref<string>("");
const currentMode = ref<string>("");

const authStore = authStoreManagement();
const router = useRouter();
const route = useRoute();

const handleViewWidth: () => void = () => {
  navOnTop.value = viewPointCondition();
};

window.addEventListener("resize", handleViewWidth);

const selectMenu: (menu: string) => void = (menu) => {
  activeMenu.value = menu;
  router.push({ name: menu });
};

provide("active-menu", {
  activeMenu,
  selectMenu,
});

const initOrgIdsForUser: () => void = () => {
  const accessToken = getItemFromLocalStorage("access_token");
  if (accessToken) {
    const parseAccessToken = parseToken(accessToken);
    if (parseAccessToken) {
      authStore.setOrgId(parseAccessToken.oid);
      authStore.setAccount(parseAccessToken.ouser);
    }
  }
};

watch(() => route.path, () => {
  const pathMode = route.fullPath.split("/")[1];
  if (pathMode === 'admin' || pathMode === 'laboratory') {
    currentMode.value = pathMode;
  };

  activeMenu.value = route.name;
}, { immediate: true });

onMounted(() => {
  initOrgIdsForUser();
});
</script>

<style scoped lang="scss">
.main-layout {
  height: 100%;
  overflow: hidden;

  .main-content__container {
    width: 92%;
    height: 100%;
    background-color: $colorBlueDarker;

    .main-content__body {
      height: 100%;
      padding: $spaceL;
      border-radius: $radiusL;

      div {
        width: 100%;
      }
    }
  }

  &.vertical {
    .main-content__container {
      width: 100%;
      flex: auto;
    }
  }
}
</style>
