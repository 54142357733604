<template>
  <svg width="35" height="35" viewBox="0 0 41 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_24_969" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="41" height="39">
      <path d="M40.528 0H0.527954V39H40.528V0Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_24_969)">
      <path d="M28.164 4.28576V1.69976C28.1646 1.47487 28.12 1.25215 28.033 1.04481C27.9459 0.83747 27.818 0.64974 27.657 0.49276C27.3258 0.17172 26.8827 -0.0078125 26.4215 -0.0078125C25.9602 -0.0078125 25.5171 0.17172 25.186 0.49276C25.0247 0.649601 24.8968 0.837305 24.8097 1.04468C24.7226 1.25205 24.6781 1.47484 24.679 1.69976V4.28576C24.6805 4.5862 24.762 4.88083 24.915 5.13935C25.0681 5.39788 25.2873 5.61098 25.55 5.75676C25.8162 5.90611 26.1162 5.98455 26.4215 5.98455C26.7267 5.98455 27.0268 5.90611 27.293 5.75676C27.5557 5.61098 27.7748 5.39788 27.9279 5.13935C28.081 4.88083 28.1625 4.5862 28.164 4.28576Z" fill="#F7F8FB"/>
      <path d="M10.9009 4.28662V1.70062C10.8996 1.40001 10.8182 1.10518 10.6651 0.846464C10.512 0.587747 10.2928 0.374487 10.0299 0.228624C9.7637 0.0793632 9.46362 0.000976563 9.15842 0.000976562C8.85322 0.000976563 8.55313 0.0793632 8.28692 0.228624C8.02402 0.374275 7.80467 0.587322 7.65141 0.845856C7.49815 1.10439 7.41652 1.39908 7.41492 1.69962V4.28662C7.41612 4.58689 7.49725 4.88142 7.64998 5.13994C7.8027 5.39847 8.02151 5.61167 8.28392 5.75762C8.55013 5.90689 8.85022 5.98527 9.15542 5.98527C9.46062 5.98527 9.76071 5.90689 10.0269 5.75762C10.2902 5.61223 10.5099 5.3993 10.6636 5.14075C10.8172 4.88219 10.8991 4.58737 10.9009 4.28662Z" fill="#F7F8FB"/>
      <path d="M29.764 18.1709C27.1083 18.1693 24.5457 19.1495 22.569 20.9229V18.6959H20.653V24.4469H26.553V22.5789H23.568C24.5143 21.6472 25.6591 20.9413 26.9166 20.5141C28.1741 20.0869 29.5119 19.9494 30.83 20.1119C32.1434 20.2663 33.4043 20.718 34.5169 21.4328C35.6295 22.1475 36.5646 23.1065 37.251 24.2369C37.9235 25.3529 38.3231 26.612 38.4171 27.9116C38.5111 29.2112 38.2969 30.5147 37.792 31.7159C37.2753 32.9294 36.4868 34.008 35.4872 34.8685C34.4877 35.729 33.3038 36.3484 32.027 36.6789C30.7413 37.0196 29.3953 37.0663 28.0891 36.8154C26.7829 36.5646 25.55 36.0226 24.482 35.2299C23.4305 34.4518 22.5756 33.4386 21.9852 32.2713C21.3949 31.1041 21.0856 29.815 21.082 28.5069H19.163C19.1653 29.8703 19.4409 31.2195 19.9733 32.4747C20.5058 33.7298 21.2843 34.8656 22.263 35.8149C24.2751 37.7567 26.9622 38.8418 29.7585 38.8418C32.5547 38.8418 35.2419 37.7567 37.254 35.8149C38.2358 34.8678 39.0167 33.7325 39.5501 32.4769C40.0835 31.2213 40.3584 29.8711 40.3584 28.5069C40.3584 27.1427 40.0835 25.7925 39.5501 24.5369C39.0167 23.2813 38.2358 22.146 37.254 21.1989C35.2464 19.2535 32.5595 18.1673 29.764 18.1709Z" fill="#F7F8FB"/>
      <path d="M28.804 22.8257V29.4387H34.704V27.5707H30.721V22.8257H28.804Z" fill="#F7F8FB"/>
      <path d="M35.052 17.7469V6.85887C35.051 6.38618 34.9552 5.91849 34.7704 5.48342C34.5856 5.04836 34.3155 4.65476 33.976 4.32587C33.2791 3.65077 32.3462 3.27438 31.376 3.27687H29.801V4.28587C29.7982 4.86883 29.6402 5.44053 29.3432 5.94215C29.0461 6.44377 28.6208 6.85719 28.111 7.13987C27.5946 7.42955 27.0125 7.58169 26.4205 7.58169C25.8284 7.58169 25.2463 7.42955 24.73 7.13987C24.2202 6.85711 23.7949 6.44367 23.4979 5.94207C23.2009 5.44047 23.0428 4.86881 23.04 4.28587V3.27687H12.54V4.28587C12.5372 4.86883 12.3792 5.44053 12.0822 5.94215C11.7851 6.44377 11.3598 6.85719 10.85 7.13987C10.3338 7.42946 9.75183 7.58156 9.15995 7.58156C8.56808 7.58156 7.98614 7.42946 7.46995 7.13987C6.96011 6.85719 6.53479 6.44377 6.23775 5.94215C5.94072 5.44053 5.7827 4.86883 5.77995 4.28587V3.27687H4.20095C3.23073 3.27449 2.29788 3.65086 1.60095 4.32587C1.26203 4.65504 0.992478 5.04877 0.80819 5.48381C0.623902 5.91885 0.528616 6.3864 0.527954 6.85887L0.527954 30.0789C0.529006 30.5515 0.624764 31.0192 0.809572 31.4543C0.99438 31.8893 1.26449 32.2829 1.60395 32.6119C2.30094 33.2868 3.23375 33.6631 4.20395 33.6609H18.728C18.4318 33.0597 18.1883 32.434 18 31.7909H4.20195C3.73897 31.7921 3.29374 31.6128 2.96095 31.2909C2.79856 31.1339 2.66932 30.9459 2.58086 30.738C2.4924 30.5302 2.44653 30.3067 2.44595 30.0809V11.0629H33.135V17.0379C33.7928 17.2208 34.4343 17.458 35.053 17.7469H35.052Z" fill="#F7F8FB"/>
    </g>
  </svg>
</template>
