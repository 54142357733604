import { createI18n } from "vue-i18n";

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: "en",
  fallbackLocale: "fr",
  messages: {
    en: require("../content/en/en-EN.json"),
    fr: require("../content/fr/fr-FR.json"),
  },
});

export default i18n;
