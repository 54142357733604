<template>
  <svg width="45" height="45" viewBox="36 30 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M64.8362 75H55.1636C50.8781 74.9953 46.7696 73.2908 43.7393 70.2605C40.7091 67.2303 39.0046 63.1217 38.9999 58.8363V49.1637C39.0046 44.8783 40.7091 40.7697 43.7393 37.7395C46.7696 34.7092 50.8781 33.0047 55.1636 33H64.8362C69.1216 33.0047 73.2302 34.7092 76.2604 37.7395C79.2907 40.7697 80.9952 44.8783 80.9999 49.1637V58.8363C80.9952 63.1217 79.2907 67.2303 76.2604 70.2605C73.2302 73.2908 69.1216 74.9953 64.8362 75Z" fill="#009CA5"/>
    <g mask="url(#mask0_1231_2037)">
      <path d="M62.3944 65.4H57.0054C54.6178 65.3974 52.3287 64.4477 50.6404 62.7594C48.9521 61.0712 48.0025 58.7821 47.9999 56.3945V51.0055C48.0025 48.6179 48.9521 46.3288 50.6404 44.6406C52.3287 42.9523 54.6178 42.0026 57.0054 42H62.3944C64.782 42.0026 67.071 42.9523 68.7593 44.6406C70.4476 46.3288 71.3972 48.6179 71.3999 51.0055V56.3945C71.3972 58.7821 70.4476 61.0712 68.7593 62.7594C67.071 64.4477 64.782 65.3974 62.3944 65.4ZM57.0054 43.3853C54.9851 43.3876 53.0483 44.1912 51.6198 45.6198C50.1914 47.0484 49.3879 48.9853 49.3857 51.0055V56.3945C49.3881 58.4146 50.1916 60.3514 51.62 61.7798C53.0485 63.2083 54.9852 64.0118 57.0054 64.0141H62.3944C64.4145 64.0118 66.3513 63.2083 67.7797 61.7798C69.2082 60.3514 70.0117 58.4146 70.014 56.3945V51.0055C70.0118 48.9853 69.2084 47.0484 67.7799 45.6198C66.3514 44.1912 64.4146 43.3876 62.3944 43.3853H57.0054Z" fill="#F7F8FB"/>
      <path d="M59.6993 60.9939C58.3877 60.9933 57.1057 60.604 56.0154 59.875C54.925 59.1461 54.0752 58.1102 53.5734 56.8985C53.0716 55.6867 52.9403 54.3533 53.1961 53.0669C53.4519 51.7805 54.0833 50.5988 55.0105 49.6712C55.0748 49.6069 55.1512 49.5558 55.2353 49.521C55.3193 49.4862 55.4094 49.4683 55.5004 49.4683C55.5914 49.4683 55.6815 49.4862 55.7656 49.521C55.8496 49.5558 55.926 49.6069 55.9904 49.6712C56.0547 49.7355 56.1057 49.8119 56.1406 49.896C56.1754 49.98 56.1933 50.0701 56.1933 50.1611C56.1933 50.2521 56.1754 50.3422 56.1406 50.4263C56.1057 50.5104 56.0547 50.5867 55.9904 50.6511C55.2567 51.3849 54.757 52.3199 54.5546 53.3377C54.3522 54.3555 54.4561 55.4104 54.8532 56.3691C55.2503 57.3279 55.9228 58.1474 56.7856 58.724C57.6483 59.3006 58.6627 59.6084 59.7004 59.6086C60.7382 59.6087 61.7528 59.301 62.6157 58.7245C63.4787 58.1479 64.1513 57.3285 64.5486 56.3697C64.9458 55.4109 65.0498 54.3558 64.8474 53.3379C64.6451 52.32 64.1454 51.385 63.4117 50.6511C63.3473 50.5867 63.2963 50.5104 63.2615 50.4263C63.2266 50.3422 63.2087 50.2521 63.2087 50.1611C63.2087 50.0701 63.2266 49.98 63.2615 49.896C63.2963 49.8119 63.3473 49.7355 63.4117 49.6712C63.476 49.6069 63.5524 49.5558 63.6365 49.521C63.7205 49.4862 63.8106 49.4683 63.9016 49.4683C63.9926 49.4683 64.0827 49.4862 64.1668 49.521C64.2508 49.5558 64.3272 49.6069 64.3915 49.6712C65.3189 50.599 65.9503 51.7809 66.206 53.0675C66.4617 54.3541 66.3302 55.6877 65.8281 56.8995C65.3261 58.1114 64.4759 59.1472 63.3852 59.876C62.2945 60.6048 61.0122 60.9938 59.7004 60.9939" fill="#F7F8FB"/>
      <path d="M59.7028 54.3914C59.5191 54.3914 59.3429 54.3184 59.213 54.1885C59.0831 54.0586 59.0101 53.8825 59.0101 53.6988V47.0982C59.0101 46.9144 59.0831 46.7382 59.2131 46.6082C59.343 46.4783 59.5193 46.4053 59.7031 46.4053C59.8868 46.4053 60.0631 46.4783 60.193 46.6082C60.323 46.7382 60.396 46.9144 60.396 47.0982V53.6988C60.396 53.8825 60.323 54.0586 60.1931 54.1885C60.0632 54.3184 59.8871 54.3914 59.7034 54.3914" fill="#F7F8FB"/>
    </g>
  </svg>
</template>
