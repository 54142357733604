import { defineStore } from "pinia";
import { ref } from "vue";

import Ouser from "@/entities/ouser";

export const authStoreManagement = defineStore("auth", () => {
  const orgId = ref<string>();
  const account = ref<Ouser>();

  const setOrgId: (id: string) => void = (id) => {
    orgId.value = id;
  };

  const setAccount: (ouser: string) => void = (ouser) => {
    const userInfo = ouser.split(",");

    account.value = {
      userHashId: userInfo[0],
      userName: userInfo[1],
      fullName: userInfo[2],
      email: userInfo[3],
    };
  };

  return {
    orgId: orgId,
    account: account,
    setOrgId,
    setAccount,
  };
});
