import axios from "axios";
import { initAuth, renewAccessToken, storeToken } from "@/services/auth";
import router from "@/router";

const getItemFromLocalStorage: (item: string) => string | null = (item) => {
  return localStorage.getItem(item);
};

const setItemToLocalStorage: (item: string, value: string) => void = (
  item,
  value
) => {
  localStorage.setItem(item, value);
};

const removeItemFromLocalStorage: (item: string) => void = (item) => {
  localStorage.removeItem(item);
};

const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 30000,
});

http.defaults.timeout = 30000;
http.interceptors.request.use(
  (config) => {
    const token = getItemFromLocalStorage("access_token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    const statusCode = error.response?.status;
    if (statusCode === 401) {
      console.log("Error: API request failed, renew access token");

      const renewAuth = await renewAccessToken();
      if (!renewAuth) {
        await initAuth();
        return;
      }
      storeToken(renewAuth);
      console.log("Token renew!");
    } else if (
      (statusCode >= 400 && statusCode <= 500) ||
      error?.code === "ERR_NETWORK"
    ) {
      await router.push({ name: "error", query: { code: statusCode } });
    } else {
      return Promise.reject(error);
    }
  }
);

const prepareParams = (params: any) => {
  let str = "";
  for (const key in params) {
    if (!params[key]) continue;

    if (Array.isArray(params[key])) {
      params[key].forEach((ele: any) => {
        str += key + "=" + encodeURIComponent(ele) + "&";
      });
    } else {
      str += key + "=" + encodeURIComponent(params[key]) + "&";
    }
  }
  return str.substring(0, str.length - 1);
};

export {
  http,
  getItemFromLocalStorage,
  setItemToLocalStorage,
  removeItemFromLocalStorage,
  prepareParams,
};
