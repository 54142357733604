<template>
  <div class="side-bar__container bg-ghost-white flex vertical">
    <div class="side-bar__top">
      <div class="side-bar__mode">
        <a-tooltip
          placement="left"
          trigger="click"
          color="#1E2433"
          v-model:visible="tooltipModeVisible"
        >
          <template #title>
            <menu-jimini-icon
              v-if="props.mode !== 'laboratory'"
              class="mode-option__icon icon-L"
              @click="selectMenu('history')"
            />
            <menu-admin-icon
              v-else
              class="mode-option__icon icon-L"
              @click="selectMenu('user-management')"
            />

            <div v-if="props.mode !== 'laboratory'" class="text-center">
              <div class="text-small">
                {{ $t("navigation-bar.laboratory-mode.title-1") }}
              </div>
              <div class="text-small">
                {{ $t("navigation-bar.laboratory-mode.title-2") }}
              </div>
            </div>

            <div class="text-small" v-else>
              {{ $t("navigation-bar.admin-mode.title") }}
            </div>
          </template>

          <div class="side-bar__mode-action">
            <menu-jimini-icon v-if="props.mode === 'laboratory'" />
            <menu-admin-icon v-else />

            <three-dot-green-icon
              class="side-bar__three-dot"
              v-if="tooltipModeVisible"
            />
            <three-dot-white-icon class="side-bar__three-dot" v-else />
          </div>
        </a-tooltip>
      </div>
    </div>

    <div
      class="side-bar__spacer"
      :class="{
        'next-active':
          activeMenu === 'history' || activeMenu === 'user-management',
      }"
    >
      <div class="side-bar__spacer-inside top"></div>
    </div>

    <div
      class="side-bar__main laboratory-mode"
      v-if="props.mode === 'laboratory'"
    >
      <div
        class="side-bar__history"
        :class="{ 'next-active': activeMenu === 'devices' }"
      >
        <div
          class="side-bar__history-action"
          :class="{
            active: activeMenu === 'history',
            'next-active': activeMenu === 'devices',
          }"
          @click="selectMenu('history')"
        >
          <history-active-icon class="icon-S" v-if="activeMenu === 'history'" />

          <history-white-icon class="icon-S" v-else />

          <span
            class="side-bar__label laboratory"
            :class="{ active: activeMenu === 'history' }"
          >
            {{ $t("navigation-bar.laboratory-mode.history") }}
          </span>
        </div>
      </div>

      <div
        class="side-bar__devices"
        :class="{
          'pre-active': activeMenu === 'history',
          'next-active': activeMenu === 'support',
        }"
      >
        <div
          class="side-bar__devices-action"
          :class="{
            active: activeMenu === 'devices',
            'pre-active': activeMenu === 'history',
            'next-active': activeMenu === 'support',
          }"
          @click="selectMenu('devices')"
        >
          <devices-active-icon class="icon-S" v-if="activeMenu === 'devices'" />

          <devices-white-icon class="icon-S" v-else />

          <span
            class="side-bar__label laboratory"
            :class="{ active: activeMenu === 'devices' }"
          >
            {{ $t("navigation-bar.laboratory-mode.device-management") }}
          </span>
        </div>
      </div>

      <div
        class="side-bar__callback-devices"
        :class="{ 'pre-active': activeMenu === 'devices' }"
      >
        <div
          class="side-bar__callback-devices-action"
          :class="{
            active: activeMenu === 'support',
            'pre-active': activeMenu === 'devices',
          }"
          @click="selectMenu('support')"
        >
          <callback-devices-active-icon
            class="icon-S"
            v-if="activeMenu === 'support'"
          />

          <callback-devices-white-icon class="icon-S" v-else />

          <span
            class="side-bar__label laboratory"
            :class="{ active: activeMenu === 'support' }"
          >
            {{ $t("navigation-bar.laboratory-mode.support") }}
          </span>
        </div>
      </div>
    </div>

    <div class="side-bar__main admin-mode" v-else>
      <div
        class="side-bar__users"
        :class="{ 'next-active': activeMenu === 'new-user' }"
      >
        <div
          class="side-bar__users-action"
          :class="{
            active: activeMenu === 'user-management',
            'next-active': activeMenu === 'new-user',
          }"
          @click="selectMenu('user-management')"
        >
          <utilisateurs-active-icon
            class="icon-S"
            v-if="activeMenu === 'user-management'"
          />

          <utilisateurs-white-icon class="icon-S" v-else />

          <span
            class="side-bar__label admin"
            :class="{ active: activeMenu === 'user-management' }"
          >
            {{ $t("navigation-bar.admin-mode.user-managerment") }}
          </span>
        </div>
      </div>

      <div
        class="side-bar__new-user"
        :class="{
          'pre-active': activeMenu === 'user-management',
          'next-active': activeMenu === 'organizations',
        }"
      >
        <div
          class="side-bar__new-user-action"
          :class="{
            'pre-active': activeMenu === 'user-management',
            active: activeMenu === 'new-user',
            'next-active': activeMenu === 'organizations',
          }"
          @click="selectMenu('new-user')"
        >
          <creauser-active-icon
            class="icon-L"
            v-if="activeMenu === 'new-user'"
          />
          <creauser-white-icon class="icon-L" v-else />

          <span
            class="side-bar__label admin"
            :class="{ active: activeMenu === 'new-user' }"
          >
            {{ $t("navigation-bar.admin-mode.user-creation") }}
          </span>
        </div>
      </div>

      <div
        class="side-bar__organization"
        :class="{ 'pre-active': activeMenu === 'new-user' }"
      >
        <div
          class="side-bar__organization-action"
          :class="{
            'pre-active': activeMenu === 'new-user',
            active: activeMenu === 'organizations',
          }"
          @click="selectMenu('organizations')"
        >
          <organization-active-icon
            class="icon-L"
            v-if="activeMenu === 'organizations'"
          />
          <organization-white-icon class="icon-L" v-else />

          <span
            class="side-bar__label admin"
            :class="{ active: activeMenu === 'organizations' }"
          >
            {{ $t("navigation-bar.admin-mode.organization-management") }}
          </span>
        </div>
      </div>
    </div>

    <div
      class="side-bar__spacer"
      :class="{
        'pre-active':
          activeMenu === 'organizations' || activeMenu === 'support',
      }"
    >
      <div class="side-bar__spacer-inside bottom"></div>
    </div>

    <div class="side-bar__bottom">
      <div class="side-bar__bottom-content flex gap-S">
        <a-tooltip
          placement="left"
          trigger="click"
          color="#1E2433"
          v-model:visible="tooltipLogoutVisible"
          overlayClassName="tooltip-logout"
        >
          <template #title>
            <div
              class="account-option__icon flex vertical align-center"
              @click="selectMenu('account')"
            >
              <user-icon class="icon-M" />
              <div class="text-small">
                {{ $t("navigation-bar.general.profile-1") }}
              </div>
              <div class="text-small">
                {{ $t("navigation-bar.general.profile-2") }}
              </div>
            </div>

            <div
              class="logout-option__icon flex vertical align-center"
              @click="logoutHandler"
            >
              <log-out-icon class="icon-M" />
              <div class="logout-option__text text-small text-center">
                {{ $t("navigation-bar.general.client-disconnect") }}
              </div>
            </div>
          </template>

          <div class="side-bar__logo flex">
            <usense-logo-icon />

            <three-dot-green-icon
              class="side-bar__three-dot"
              v-if="tooltipLogoutVisible"
            />
            <three-dot-white-icon class="side-bar__three-dot" v-else />
          </div>
        </a-tooltip>
        <span
          class="side-bar__version title-light text-small"
          @click="toggleVersionModal"
        >
          v. bêta
        </span>
      </div>
    </div>
  </div>

  <a-modal
    v-model:visible="modalVersionVisible"
    :closable="false"
    :footer="null"
    centered
    :width="400"
  >
    <div class="flex align-center gap-XL version-update__modal">
      <version-update-icon class="icon-3XL" />
      <div class="version-update__modal-message">
        <div>{{ $t("logo.popup.information-text-1") }}</div>
        <div>{{ $t("logo.popup.information-text-2") }}</div>
        <div class="version-update__modal-text">{{ $t("logo.popup.information-text-3") }}</div>
      </div>
    </div>
  </a-modal>
</template>

<script lang="ts" setup>
import MenuAdminIcon from "@/components/icons/menu-admin.vue";
import MenuJiminiIcon from "@/components/icons/menu-jimini.vue";
import HistoryActiveIcon from "@/components/icons/history-grey-no-border.vue";
import HistoryWhiteIcon from "@/components/icons/history-white-no-border.vue";
import DevicesWhiteIcon from "@/components/icons/clicscan-white.vue";
import DevicesActiveIcon from "@/components/icons/clicscan-green-no-border.vue";
import CallbackDevicesActiveIcon from "@/components/icons/clicscan-error-yellow.vue";
import CallbackDevicesWhiteIcon from "@/components/icons/clicscan-error-white-yellow.vue";
import UsenseLogoIcon from "@/components/icons/usense-logo.vue";
import ThreeDotGreenIcon from "@/components/icons/three-dot-green-vertical.vue";
import ThreeDotWhiteIcon from "@/components/icons/three-dot-white-vertical.vue";
import UtilisateursWhiteIcon from "@/components/icons/utilisateurs-white.vue";
import UtilisateursActiveIcon from "@/components/icons/utilisateurs-green.vue";
import CreauserWhiteIcon from "@/components/icons/creauser-white.vue";
import CreauserActiveIcon from "@/components/icons/creauser-green.vue";
import OrganizationWhiteIcon from "@/components/icons/organization-white.vue";
import OrganizationActiveIcon from "@/components/icons/organization-green.vue";
import LogOutIcon from "@/components/icons/log-out.vue";
import VersionUpdateIcon from "@/components/icons/sync-yellow-border.vue";

import { inject, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { logout } from "@/services/auth";
import UserIcon from "@/components/icons/user-white-fill.vue";

const { activeMenu, selectMenu } = inject("active-menu");

// eslint-disable-next-line no-undef
const props = defineProps({
  mode: {
    type: String,
    default: "admin",
  },
});

const tooltipModeVisible = ref<boolean>(false);
const tooltipLogoutVisible = ref<boolean>(false);
const modalVersionVisible = ref<boolean>(false);
const route = useRoute();

const logoutHandler: () => void = () => {
  logout();
};

const toggleVersionModal: () => void = () => {
  modalVersionVisible.value = !modalVersionVisible.value;
};

watch(
  () => route.path,
  () => {
    tooltipLogoutVisible.value = false;
    tooltipModeVisible.value = false;
  }
);
</script>

<style lang="scss" scoped>
.side-bar {
  &__container {
    height: 100vh;
    width: 8%;

    div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      div {
        background-color: $colorBlueDarker;
        width: 100%;
        cursor: pointer;
        justify-content: center;

        div {
          border-top-left-radius: $radiusXL;
          border-bottom-left-radius: $radiusXL;

          &.active {
            background-color: $colorGhostWhite;
          }
        }

        &.next-active {
          border-bottom-right-radius: $radiusXL;
        }

        &.pre-active {
          border-top-right-radius: $radiusXL;
        }
      }

      &.laboratory-mode {
        div {
          &:hover {
            svg {
              width: $iconM;
              height: $iconM;
            }
          }
        }
      }

      &.admin-mode {
        div {
          &:hover {
            svg {
              width: $iconXL;
              height: $iconXL;
            }
          }
        }
      }
    }
  }

  &__top {
    height: 15%;

    div {
      flex: 1;
      padding-left: $spaceS;

      div {
        height: 100%;

        svg {
          width: $iconL !important;
          height: $iconL !important;
          margin-right: $spaceL;

          &:hover {
            width: $iconXL !important;
            height: $iconXL !important;
          }
        }
      }

      &.side-bar__mode {
        &-action {
          flex-direction: row !important;
          padding-left: 4vh !important;

          svg {
            margin-right: $spaceS;
          }

          svg.side-bar__three-dot {
            width: $iconXS !important;
            height: $iconXS !important;
            margin-right: 0;

            &:hover {
              width: $iconXS !important;
              height: $iconXS !important;
            }
          }
        }
      }
    }
  }

  &__main {
    height: 45%;

    div {
      flex: 1;
      padding-left: $spaceS;

      div {
        height: 100%;
        padding-right: $spaceS;
      }
    }
  }

  &__bottom {
    height: 15%;

    > div {
      height: 100%;
    }
  }

  &__spacer {
    height: 12.5%;
    background-color: $colorBlueDarker;
    padding: 0 $spaceS;

    &.next-active {
      border-bottom-right-radius: $radiusXL;
    }

    &.pre-active {
      border-top-right-radius: $radiusXL;
    }

    &-inside {
      height: 100%;

      &.top {
        border-bottom-right-radius: $radiusXL;
        border-top: solid 2px $colorGhostWhite;
      }

      &.bottom {
        border-top-right-radius: $radiusXL;
        border-bottom: solid 2px $colorGhostWhite;
      }
    }
  }

  &__logo {
    flex-direction: row !important;
    margin-left: 2vw;

    svg {
      width: $icon2XL;
      height: $icon3XL;

      &.side-bar__three-dot {
        width: $iconXS !important;
        height: $iconXS !important;
      }
    }
  }

  &__version {
    color: $colorBluePeriwinkle;
  }

  &__label {
    color: $colorGhostWhite;
    font-weight: $weightXL;
    max-width: 6vw;
    text-align: center;

    &.laboratory {
      font-size: $textS;
    }

    &.admin {
      font-size: 1.7vmin;
    }

    &.active {
      color: $colorBlueDarker;
    }
  }
}

.mode-option__icon,
.logout-option__icon,
.account-option__icon {
  cursor: pointer;
}
.logout-option__text {
  width: 12.1vh;
}
.version-update__modal {
  &-message {
    text-align: center;
  }
}
</style>
