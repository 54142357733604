<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="mask0_14_609" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
      <path d="M35.2261 0.129883H0.226074V35.1139H35.2261V0.129883Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_14_609)">
      <path d="M26.045 23.8968C28.4089 21.0062 29.5713 17.3175 29.2918 13.5938C29.0122 9.87017 27.3121 6.39641 24.5431 3.89111C21.7741 1.38582 18.1481 0.040678 14.4151 0.133939C10.6821 0.2272 7.12782 1.75173 4.48738 4.39217C1.84694 7.03261 0.322415 10.5869 0.229154 14.3199C0.135893 18.0529 1.48103 21.6789 3.98633 24.4479C6.49162 27.2169 9.96539 28.917 13.6891 29.1966C17.4127 29.4761 21.1014 28.3137 23.992 25.9498L32.726 34.6838C32.9982 34.958 33.3681 35.1128 33.7544 35.1143C34.1407 35.1158 34.5117 34.9638 34.786 34.6918L34.794 34.6838C35.0683 34.4116 35.2233 34.0418 35.2252 33.6554C35.2271 33.269 35.0756 32.8977 34.804 32.6228L34.794 32.6158L26.045 23.8968ZM14.779 26.3298C12.4757 26.3296 10.2243 25.6464 8.30923 24.3667C6.39421 23.0869 4.90167 21.2681 4.02033 19.1401C3.139 17.0121 2.90845 14.6706 3.35785 12.4115C3.80724 10.1525 4.9164 8.07751 6.54506 6.44884C8.17372 4.82018 10.2487 3.71103 12.5078 3.26163C14.7668 2.81224 17.1083 3.04278 19.2363 3.92412C21.3643 4.80545 23.1831 6.29799 24.4629 8.21301C25.7427 10.128 26.4258 12.3795 26.426 14.6828C26.4262 16.2124 26.125 17.7269 25.5397 19.1401C24.9544 20.5532 24.0965 21.8372 23.015 22.9188C21.9334 24.0003 20.6494 24.8582 19.2363 25.4435C17.8232 26.0288 16.3086 26.3299 14.779 26.3298Z" fill="#C6D6E2"/>
      <path d="M23.072 13.7998H5.78898C5.49565 13.7998 5.21434 13.9163 5.00692 14.1237C4.79951 14.3312 4.68298 14.6125 4.68298 14.9058C4.68298 15.1991 4.79951 15.4804 5.00692 15.6879C5.21434 15.8953 5.49565 16.0118 5.78898 16.0118H23.072C23.3653 16.0118 23.6466 15.8953 23.854 15.6879C24.0615 15.4804 24.178 15.1991 24.178 14.9058C24.178 14.6125 24.0615 14.3312 23.854 14.1237C23.6466 13.9163 23.3653 13.7998 23.072 13.7998Z" fill="#C6D6E2"/>
      <path d="M20.59 8.87793H8.26896C7.97563 8.87793 7.69432 8.99445 7.4869 9.20187C7.27949 9.40929 7.16296 9.6906 7.16296 9.98393C7.16296 10.2773 7.27949 10.5586 7.4869 10.766C7.69432 10.9734 7.97563 11.0899 8.26896 11.0899H20.59C20.8833 11.0899 21.1646 10.9734 21.372 10.766C21.5794 10.5586 21.696 10.2773 21.696 9.98393C21.696 9.6906 21.5794 9.40929 21.372 9.20187C21.1646 8.99445 20.8833 8.87793 20.59 8.87793Z" fill="#C6D6E2"/>
      <path d="M20.59 18.7148H8.26896C7.97563 18.7148 7.69432 18.8314 7.4869 19.0388C7.27949 19.2462 7.16296 19.5275 7.16296 19.8208C7.16296 20.1142 7.27949 20.3955 7.4869 20.6029C7.69432 20.8103 7.97563 20.9268 8.26896 20.9268H20.59C20.8833 20.9268 21.1646 20.8103 21.372 20.6029C21.5794 20.3955 21.696 20.1142 21.696 19.8208C21.696 19.5275 21.5794 19.2462 21.372 19.0388C21.1646 18.8314 20.8833 18.7148 20.59 18.7148Z" fill="#C6D6E2"/>
    </g>
  </svg>
</template>
